import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Services from './Pages/Services';
import SampleTracks from './Pages/SampleTracks';
import Contact from './Pages/Contact';
import TermsConditions from './Pages/TermsConditions';

// ScrollToTop component inside the App
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop /> {/* Scroll to top when path changes */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/services' element={<Services />} />
        {/* <Route path='/sample-tracks' element={<SampleTracks />} /> */}
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/terms-and-conditions' element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
