import Screen from "../Components/Screen"
import TextImage from "../Components/TextImage"
import VSCarousel from "../Components/VSCarousel"
import about from "../Images/about.png"
import bg from "../Images/carousel.jpg"
import suit from "../Images/suit.png"
import industry from "../Images/industry.png"
import city from "../Images/city.png"
import video from "../Images/video.png"
import map from "../Images/map.png"
import ben from "../Images/Ben.png"
import joe from "../Images/JoeNew.jpg"
import jack from "../Images/Jack.png"
import rae from "../Images/Rae.png"
import lili from "../Images/Lili.png"
import reece from "../Images/Reece.png"
import { FaInstagram } from "react-icons/fa6"
const AboutUs = () => {
    const tablet = window.matchMedia("(max-width: 1024px)").matches
    return (
        <Screen>
            <TextImage
                image={about}
                title={<h2 className="title">ABOUT
                    <span className="title title--thin"> VAULT</span>STUDIOS</h2>}>
                <p>Vault Studios was founded in 2023 to be a creative outlet for all musicians in order to bring their vision to life. The founders, Ben & Joe, have years of experience producing music as well as writing their own and performing across multiple bands and solo projects.</p>
                <p>The opportunity arose to start up a studio, and the decision was made in a heartbeat. Not only does it provide great resource for our own projects but gave us the chance to do the same for others. We want to create a studio with a professional environment with high-quality equipment to ensure we can get the best sound to cover all genres of music.</p>
                <p>With our combined music experience and knowledge, along with our production, videography and photography packages, we feel we can give everyone who comes to Vault Studios a second-to-none experience with more than just industry-standard results.</p>
                <a href="mailto:admin@vaultstudios.co.uk">Admin@VaultStudios.co.uk</a>
            </TextImage>
            <VSCarousel
                items={[
                    {
                        title: "AVAILABILITY TO SUIT YOU",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">At Vault Studios, we are open all day, every day; including evenings, nights and weekends – to suit our clients at no extra cost</p>
                            </>,
                        img: suit
                    },
                    {
                        title: "HIGH QUALITY EQUIPMENT",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">We have a great range of instruments and both analog and digital equipment to get the perfect sound for each project</p>
                            </>,
                        img: bg
                    },
                    {
                        title: "INDUSTRY EXPERIENCE",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Everyone at Vault Studios has years of experience in their respective fields – bringing the best of the best to our clients</p>
                            </>,
                        img: industry
                    },
                    {
                        title: "GREAT LOCATION & FACILITIES",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Our location is right in the heart of Sheffield, and is purpose-built and secure to ensure everyone’s equipment is safe</p>
                            </>,
                        img: city
                    },
                    {
                        title: "ADD-ON SERVICES",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Not only do we produce music at Vault Studios – we can collate content for social media, promote your projects, and distribute across all platforms</p>
                            </>,
                        img: video
                    },
                ]}
            />
            <div className="d-flex p-5">
                <div className="d-flex justify-content-center align-items-center w-100 flex-column">
                    <h2 className="title">MEET THE TEAM</h2>
                    <p className={`text-center ${tablet ? "w-100" : "w-50"}`}>
                        At VaultStudios, we've assembled a dynamic team of music enthusiasts and industry professionals, each bringing a unique set of skills and a shared passion for excellence. Get acquainted with the talented individuals who make the magic happen.
                    </p>
                    <div className="d-flex flex-wrap justify-content-between w-100 mb-5">
                        <div style={{ backgroundImage: `url(${ben})` }} className="about-emp">
                            <div className="about-emp-details">
                                <p className="about-emp-details__name">Ben Mallett</p>
                                <p className="about-emp-details__job">Founder / Technician</p>
                                <a target="_blank" href="https://www.instagram.com/vault_studios_" className="team-social">
                                    <FaInstagram size={20} color="white" />
                                    @vault_studios_
                                </a>
                            </div>
                        </div>
                        <div style={{ backgroundImage: `url(${joe})` }} className="about-emp">
                            <div className="about-emp-details">
                                <p className="about-emp-details__name">Joe Lawson</p>
                                <p className="about-emp-details__job">Founder / Engineer</p>
                                <a target="_blank" href="https://www.instagram.com/vault_studios_" className="team-social">
                                    <FaInstagram size={20} color="white" />
                                    @vault_studios_
                                </a>
                            </div>
                        </div>
                        <div style={{ backgroundImage: `url(${jack})` }} className="about-emp">
                            <div className="about-emp-details">
                                <p className="about-emp-details__name">Jack Godfrey</p>
                                <p className="about-emp-details__job">Videography Director</p>
                                <a target="_blank" href="https://www.instagram.com/joff.ski" className="team-social">
                                    <FaInstagram size={20} color="white" />
                                    @joff.ski
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between w-100">
                        <div style={{ backgroundImage: `url(${reece})` }} className="about-emp">
                            <div className="about-emp-details">
                                <p className="about-emp-details__name">Reece Pinches</p>
                                <p className="about-emp-details__job">Photographer</p>
                                <a target="_blank" href="https://www.instagram.com/reece_pinches_" className="team-social">
                                    <FaInstagram size={20} color="white" />
                                    @reece_pinches_
                                </a>
                            </div>
                        </div>
                        <div style={{ backgroundImage: `url(${lili})` }} className="about-emp">
                            <div className="about-emp-details">
                                <p className="about-emp-details__name">Lili Petals</p>
                                <p className="about-emp-details__job">Photographer</p>
                                <a target="_blank" href="https://www.instagram.com/lili_takesflickis" className="team-social">
                                    <FaInstagram size={20} color="white" />
                                    @lili_takesflickis
                                </a>
                            </div>
                        </div>
                        <div style={{ backgroundImage: `url(${rae})` }} className="about-emp">
                            <div className="about-emp-details">
                                <p className="about-emp-details__name">Rachel Rae</p>
                                <p className="about-emp-details__job">Photographer</p>
                                <a target="_blank" href="https://www.instagram.com/raewiththephotos" className="team-social">
                                    <FaInstagram size={20} color="white" />
                                    @raewiththephotos
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TextImage
                flipped
                image={map}
                title={<h2 className="title">WHERE TO FIND US</h2>}
                dark
            >
                <p>Vault Studios is based within FORGE Warehouse in Sheffield. FORGE is the brand-new sister venue of the reputable Southbank Warehouse, located just 100 yards down the road. Both venues put on regular events such as DJs, weddings, craft fairs, markets and of course, live bands and artists.</p>
                <p>Not only are we thankful to be associated with such highly regarded venues, but it means that Vault Studios is surrounded other creative people and is a hotspot for inspiration; getting the best results out of our small team that work within the studio as well as our clients.</p>
                <p className="m-0 font-bold">Vault Studios</p>
                <p className="m-0">FORGE</p>
                <p className="m-0">148 Effingham Road</p>
                <p className="m-0">Sheffield</p>
                <p className="m-0">South Yorkshire</p>
                <p className="m-0">S4 7ZB</p>
            </TextImage>
        </Screen>
    )
}

export default AboutUs