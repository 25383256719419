import { Link } from "react-router-dom"
import TextImage from "../Components/TextImage"
import about from "../Images/wallLight.png"
import Screen from "../Components/Screen"
import Packages from "../Components/Packages"
import { PiGuitarLight, PiMicrophoneStageLight, PiCameraLight, PiMusicNotesLight, PiHeadphones, PiHouse } from "react-icons/pi";
import VSCarousel from "../Components/VSCarousel"
import bg from "../Images/carousel.jpg"
import suit from "../Images/suit.png"
import industry from "../Images/industry.png"
import city from "../Images/city.png"
import video from "../Images/video.png"
const Services = () => {
    return (
        <Screen>
            <div className="z-998">
            <TextImage
                image={about}
                title={<h2 className="title">OUR SERVICES</h2>}
                flipped
            >
                <p>Welcome to Vault Studios, where we offer a comprehensive range of professional music services to artists and musicians alike. Our state-of-the-art studio is equipped with cutting-edge technology and staffed by a team of experienced and passionate experts, dedicated to bringing your musical vision to life. Our services include the following packages below.</p>
                <Link to={"/contact-us"}>Get in touch with us</Link>
            </TextImage>
            </div>
            <Packages
                title="STUDIO PACKAGES"
                extra="Albums and EPs can be completed at bespoke prices upon request"
                desc="At Vault Studios, we offer a variety of carefully curated studio packages to cater to the diverse needs of musicians and artists. Each package is designed to provide a seamless and rewarding music production experience, supported by our state-of-the-art facilities and talented team of professionals."
                items={[
                    {
                        icon: <PiHeadphones color="#F9C340" size={50} />,
                        price: "£50",
                        title: "DISTRIBUTION",
                        included: [
                            "Vault Studios label",
                            "All platforms",
                            "Quick turnaround",
                        ]
                    },
                    {
                        icon: <PiMicrophoneStageLight color="#F9C340" size={50} />,
                        price: "£75",
                        title: "SESSION WORK",
                        subPrice: "/Day",
                        included: [
                            "Professional musicians",
                            "All instruments",
                            "Compositions provided",
                            "Range of musicians"
                        ]
                    },
                    {
                        icon: <PiHouse color="#F9C340" size={50} />,
                        price: "£150",
                        title: "STUDIO DAY RATE",
                        included: [
                            "Studio hire",
                            "First mix",
                            "Instrument usage",
                            "Recording equipment"
                        ]
                    },
                    {
                        icon: <PiCameraLight color="#F9C340" size={50} />,
                        price: "£200",
                        title: "LIVE SESSION",
                        included: [
                            "Professional video",
                            "Full audio mix",
                            "Three locations",
                            "Full video edit"
                        ]
                    },
                    {
                        icon: <PiGuitarLight color="#F9C340" size={50} />,
                        price: "£100",
                        title: "TRACK MIX",
                        included: [
                            "Full mix",
                            "Internal recordings",
                            "External recordings",
                        ]
                    },
                    {
                        icon: <PiMusicNotesLight color="#F9C340" size={50} />,
                        price: "£100",
                        title: "TRACK MASTER",
                        included: [
                            "Full master",
                            "Internal recordings",
                            "External recordings",
                        ]
                    },
                ]}
            />
                        <VSCarousel
                items={[
                    {
                        title: "AVAILABILITY TO SUIT YOU",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">At Vault Studios, we are open all day, every day; including evenings, nights and weekends – to suit our clients at no extra cost</p>
                            </>,
                        img: suit
                    },
                    {
                        title: "HIGH QUALITY EQUIPMENT",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">We have a great range of instruments and both analog and digital equipment to get the perfect sound for each project</p>
                            </>,
                        img: bg
                    },
                    {
                        title: "INDUSTRY EXPERIENCE",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Everyone at Vault Studios has years of experience in their respective fields – bringing the best of the best to our clients</p>
                            </>,
                        img: industry
                    },
                    {
                        title: "GREAT LOCATION & FACILITIES",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Our location is right in the heart of Sheffield, and is purpose-built and secure to ensure everyone’s equipment is safe</p>
                            </>,
                        img: city
                    },
                    {
                        title: "ADD-ON SERVICES",
                        description:
                            <>
                                <p className="vsCarousel-item-content-text__item">Not only do we produce music at Vault Studios – we can collate content for social media, promote your projects, and distribute across all platforms</p>
                            </>,
                        img: video
                    },
                ]}
            />
                        <Packages
                title="OTHER SERVICES"
                desc="At Vault Studios, we pride ourselves on offering a range of popular packages designed to cater to the diverse needs of musicians and artists. With our state-of-the-art facilities and experienced team, we aim to provide a seamless and rewarding music production experience."
                items={[
                    {
                        icon: <PiMicrophoneStageLight color="#F9C340" size={50} />,
                        price: "£200",
                        title: "LIVE SESSION",
                        included: [
                            "Professional video",
                            "Full audio mix",
                            "Three locations",
                            "Full video edit"
                        ]
                    },
                    {
                        icon: <PiCameraLight color="#F9C340" size={50} />,
                        price: "£280",
                        subPrice: "/Day",
                        title: "VIDEOGRAPHY",
                        included: [
                            "Professional video",
                            "Full video editing",
                            "“Behind the scenes”",
                        ]
                    },
                ]}
            />
        </Screen>

    )
}

export default Services