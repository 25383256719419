import { ReactNode } from "react";
import { Button } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";

interface Props {
    title: string;
    desc: string;
    skewed?: boolean;
    extra?: string;
    items: {
        icon: ReactNode;
        title: string;
        price: string;
        subPrice?: string;
        included: string[];
    }[];
}

const Packages = ({ desc, title, items, skewed = false, extra }: Props) => {
    return (
        <div style={{ position: "relative", background: skewed ? "#183E47" : "#123035" }}>
            {skewed && <div className="skewed"></div>}
            <div className="p-5" style={{ position: "relative", zIndex: "100" }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h2 className="title">{title}</h2>
                    <p className="packages-desc">{desc}</p>
                    <p className="packages-extra">{extra}</p>
                </div>
                <div className="d-flex flex-row flex-wrap">
                    <div className=" d-flex w-100 flex-wrap justify-content-center">
                        {items.map((item) => {
                            const mailtoLink = `mailto:admin@vaultstudios.co.uk?subject=Enquiry about ${item.title}&body=I am interested in the ${item.title} package. `;
                            return (
                                <div className="packageItem" key={item.title}>
                                    <div className="packageItem-head">
                                        {item.icon}
                                        <p className="packageItem-head__title">{item.title}</p>
                                        <p className="packageItem-head__price">
                                            {item.price}
                                            {item.subPrice && <span className="packageItem-head__subprice">{item.subPrice}</span>}
                                        </p>
                                    </div>
                                    <div className="packageItem-container">
                                        <div className="packageItem-container-wrap">
                                            {item.included.map((string, index) => (
                                                <p key={index} className="packageItem-container__item">
                                                    <FaCircleCheck size={20} color="#0e2329" />{string}
                                                </p>
                                            ))}
                                        </div>
                                        <div className="d-flex w-100 justify-content-center align-items-center">
                                            <a href={mailtoLink}>
                                                <Button className="VSButton">ENQUIRE</Button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Packages;
